module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WP Admin Dashboard Theme","short_name":"WP Admin Theme","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"./static/images/favicon.png","legacy":true,"icon_options":{"purpose":"any maskable"},"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6ddd8f52dd7258739a1fb4a1217ecb35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-clicky/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"101289311"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
